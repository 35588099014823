import { useUser } from '~/composables/data/useUser'

export default defineNuxtRouteMiddleware(async () => {
  const localePath = useLocalePath()

  const { user, suspense } = useUser()

  try {
    await suspense()
  } catch (error) {
    console.error(error)
  }

  if (user.value && user.value.questionnaire) {
    return navigateTo(localePath('/dashboard'))
  }
})
